import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/yardscore-services/users.service';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent implements OnInit {

  public currentUser: any;
  public token: any;
  public userObtained:boolean=false;

  constructor(private userService: UserService,private auth: AuthService, private router: Router) { }

  public navigateFromLogin(): void {
    localStorage.setItem("access_token",this.currentUser.token);
    localStorage.setItem("companyId",this.currentUser.companyId);
    this.router.navigate([this.currentUser.redirectTo]);
  }

  ngOnInit(): void {
    this.auth.idTokenClaims$.subscribe((claims) => {
      if (claims) {
        this.token = claims.__raw;
        localStorage.setItem('access_token',this.token);
      }
      this.auth.getAccessTokenSilently().subscribe(authUser => {
        console.log(authUser);
        
        if (authUser && this.userObtained==false) {
          this.userObtained=true;
          this.userService.getCurrentUser().subscribe(user => {
            this.currentUser=user;
            this.navigateFromLogin();
          });
        }
      });
    });
    
  }

}


